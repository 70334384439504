<template>
  <div id="app">
    <appHeader :navActive.sync="navActive" />
    <transition :css="false" @enter="navEnter" @leave="navLeave" mode="out-in">
      <appNavigation v-if="navActive" :navActive.sync="navActive" />
    </transition>
    <div id="container">
      <div style="min-height: 100vh;">
        <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
          <router-view />
        </transition>
      </div>
      <appBanner />
      <appFooter />
    </div>
    <div class="loading" v-if="loading" @wheel.prevent>
      <img :src="require('@/assets/images/loading.svg')" alt="" />
    </div>
  </div>
</template>
<script>
  import {layouts} from "@/utils/global-methods";
  import {TweenMax, Power4} from "gsap";
  import bus from "@/utils/bus";

  export default {
    name: "app-default",

    data() {
      return {
        navActive: false,
        loading: false
      };
    },
    watch: {
      "$route.fullPath"() {
        this.navActive = false;
      },
      navActive(val) {
        if (val) {
          document.querySelector("html").style.overflow = "hidden";
        } else {
          document.querySelector("html").style.overflow = "";
        }
      }
    },
    components: {
      appHeader: layouts("app-header"),
      appNavigation: layouts("app-navigation"),
      appFooter: layouts("app-footer"),
      appBanner: layouts("app-banner")
    },
    methods: {
      navEnter(el, done) {
        // navigation bg
        const bg = el.querySelector(".bg");

        TweenMax.to(bg, 0.8, {
          y: "100%",
          ease: Power4.easeInOut
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        TweenMax.to(anime, 0.8, {
          y: "0%",
          delay: 0.3,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });

        // navigation mark
        const mark = el.querySelector(".mark-inner");
        TweenMax.to(mark, 0.8, {
          opacity: 1,
          delay: 0.3,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      navLeave(el, done) {
        // navigation bg
        const bg = el.querySelector(".bg");
        TweenMax.to(bg, 0.8, {
          y: "0%",
          delay: 0.2,
          ease: Power4.easeInOut
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        TweenMax.to(anime, 0.8, {
          y: "-110%",
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });

        // navigation mark
        const mark = el.querySelector(".mark-inner");
        TweenMax.to(mark, 0.8, {
          opacity: 0,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      pageEnter(el, done) {
        const fadeEl = [
          el,
          document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 0},
          {
            opacity: 1,
            onComplete: () => {
              this.navActive = false;
              TweenMax.set(el, {clearProps: "all"});
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        const fadeEl = [
          el,
          document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 1},
          {
            opacity: 0,
            onComplete: () => {
              done();
            }
          }
        );
      }
    },
    mounted() {
      bus.$on("loading", status => {
        this.loading = status;
      });
    }
  };
</script>
